import React from 'react'
import {Container, Row, Col, Form, Carousel} from "react-bootstrap"
import {useHistory} from 'react-router-dom';
import logo from "../../Assets/images/logo.jpeg"
import img7 from "../../Assets/images/img7.jpeg"
import med1 from "../../Assets/images/med1.jpeg"
import med2 from "../../Assets/images/med2.jpeg"
import med3 from "../../Assets/images/med3.jpeg"
import {BsDot} from 'react-icons/bs'
function Medical() {

    let history = useHistory();

  return (
    <div>
         <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
      <div className="bimage1">
              <Row style={{position: 'relative'}}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                        <Carousel.Caption className="d-none d-md-block">
                        <h5>We are Proud</h5>
                        <p>To be always on demand.</p>
                        <div style={{border: '1px solid black', padding: '5px', backgroundColor:'red', width:150, marginLeft: '450px'}}>Contact With Us</div>
                      </Carousel.Caption>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Row>
            </div>
            <Container>
            <div style={{marginTop: 40}}>
              <Row style={{position: 'relative'}}>
                <Col xs={4} md={4} lg={4}>
                  </Col>
                <Col xs={4} md={4} lg={4}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med1}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med2}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med3}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Col>
                </Row>
            </div>
            </Container>
      <div  style={{padding:45}}>
   <Container>
             <div style={{fontSize:35, fontWeight:'550', color:'red', marginTop: 40}}>
             Medical and Tourism
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            We fully understand that the health and security of your people is imperative to the successful
             operation and growth of your organization. Especially after COVID-19, health is firmly on t
             he leadership agenda. Even a minor health or security issue, if unchecked, can quickly esca
             late into a more serious problem. Our purpose is to help keep your people healthy and safe,
              so you can focus on your core activities.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            The world is a wildly varied and fast-changing place. When it comes to the health, safety, security and wellbeing of your people, every location is different, and every organization has different needs. We recognize that there is no ‘one-size-fits-all’ approach to workforce health and security. That’s why our approach is consultative.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            We work with you to assess the risks associated with every working environment, from a corporate office to a remote oil rig. We design robust and customized preventive programs to protect your people. When prevention is not possible, we offer you and your workforce access to our 24/7 Assistance Service. 

            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Be it a pandemic, a safety concern, or a mental health issue affecting one of your workers, we are there for you and for them. Our teams of multilingual doctors, nurses, security and logistics experts are ready to assist, anytime and anywhere. 
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            We see ourselves as your trusted advisor: there to help you prepare for all eventualities and build that crucial workforce resilience. Protecting your people is our priority.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            As well as contributing to your Duty of Care and sustainability agenda, these steps can reduce costs and insurance claims. This joint commitment to health and wellbeing benefits your people, your organization and your reputation. We welcome the opportunity to work with you.
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>A Global Presence</div>
                <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
                We deliver invaluable local knowledge and expertise on a global scale. Our tailor-made services integrate both health and security. This means your needs can be met by one provider and a single point of contact for your people. 
                  </p>
                  
          
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
              <div style={{marginTop: 2}}>27 Assistance Centers - You can contact us any time, any day and in 100 different languages and dialects. </div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>6,000 Medical Professionals - With extensive experience in all fields of medicine, and a thorough knowledge of the local environment and healthcare system.</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Access to 3,200 Security Specialists - Providing analysis, expert advice and location monitoring across the world.</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>53 Clinics and over 1,000 Remote and Workplace Sites - All accredited to international standards. In developed and emerging countries, offshore and remote locations.</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>103,000 Accredited Providers - In healthcare, aviation and security.</div>
            </div>

            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Did you Know?</div>
                
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>•	+9,500 Organizations trust the International SOS Group, including the majority of the Fortune Global 500</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>11,000 calls a day managed by our Assistance Centers</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>~1,600 air ambulance missions performed and flown 12,000 flight hours (eight million km) per year</div>
            </div>
        
 </Container>
</div>

        
              
              
       
    </div>
  )
}

export default Medical