import React from 'react'
import {Container, Row, Col, Form} from "react-bootstrap"
import {BsDot} from 'react-icons/bs'
import { FaBeer } from 'react-icons/fa';
import { GrAd, GrApple, GrCatalog } from 'react-icons/gr';
import {FiPhone} from 'react-icons/fi';
import {FaWhatsapp, FaInstagram} from 'react-icons/fa';
import {TiSocialTwitterCircular, TiSocialLinkedinCircular} from 'react-icons/ti';
import {RiFacebookCircleLine} from 'react-icons/ri';
import {BiCopyright} from 'react-icons/bi';
function Footer() {
  return (
    <div> <div className='footer'>
    <div className='foot'>
        <Row>
            <Col xs={12} md={3}>
                <div  style={{color:'white', marginTop:20, fontSize:15}}>
                <div style={{fontSize:22}}>ABOUT MAKLER</div>
                <div style={{marginTop:5}}>SHOW ROOM</div>
                <div>USA, Auto, King St. 665087</div>
                <div>Phone.: +7 234 949-58-83</div>
                <div>E-mail.: info@example.com</div>
                <div>© 2018 Auto LTD</div>
                <div style={{display:'flex'}}>
                    <FiPhone size={18} style={{marginTop:17}}/>
                    <div className='why' style={{marginTop:15, marginLeft:7}}>456588</div>
                </div>
                </div>
            </Col>
            <Col xs={12} md={3}>
                <div className='elements' style={{color:'white', marginTop:20, fontSize:15}}>
                <div style={{fontSize:22}}>USEFUL LINKS</div>
                <div className='why' style={{marginTop:15}}>Home</div>
                <div className='why' style={{marginTop:15}}>About us</div>
                <div className='why' style={{marginTop:15}}>Our Services</div>
                <div className='why' style={{marginTop:15}}>Contact us</div>
                </div>					
            </Col>
            <Col xs={12} md={3}>
                <div className='elements' style={{color:'white', marginTop:20, fontSize:15}}>
                <div style={{fontSize:22}}>Get To Know Us Better</div>
                <div className='why' style={{marginTop:15}}>FAQ</div>
                <div className='why' style={{marginTop:15}}>Blogs</div>
                <div className='why' style={{marginTop:15}}>Terms and Conditions</div>
                <div className='why' style={{marginTop:15}}>Privacy Policy</div>
                <div className='why' style={{marginTop:15}}>Payment Terms</div>
                </div>		
            </Col>
            <Col xs={12} md={3}> 
              <div className='elements' style={{color:'white', marginTop:20, fontSize:15}}>
                <div style={{fontSize:22}}>Contact Us</div>
                <div className='why' style={{marginTop:15}}>For More Details Call</div>
                <div className='why' style={{marginTop:15}}>+91 4662966969</div>
              </div>	
              <div style={{ marginTop: 50}}>
                    <FaInstagram size={44} style={{color:'white'}}/>
                    <RiFacebookCircleLine size={46} style={{color:'white', marginLeft:8}}/>
                    <TiSocialTwitterCircular size={50} style={{color:'white', marginLeft:8}}/>
                    <TiSocialLinkedinCircular size={50} style={{color:'white', marginLeft:8}}/>
              </div>			
            </Col>
        </Row>
       
        <Row>
            <div style={{color:'white', marginTop:150}}>
            <hr  style={{color:'white'}}/>
            </div>
        
                   {/* <div style={{ marginTop:155,  display:'flex', justifyContent: 'center' }}>
                    <FaInstagram size={20} style={{color:'white'}}/>
                    <RiFacebookCircleLine size={22} style={{color:'white', marginLeft:8}}/>
                    <TiSocialTwitterCircular size={23} style={{color:'white', marginLeft:8}}/>
                    <TiSocialLinkedinCircular size={23} style={{color:'white', marginLeft:8}}/>
                    </div> */}
        <div style={{color:'white', marginTop:50,marginBottom:50, fontSize:12, display: 'flex', justifyContent: 'center'}}>
                    <div><BiCopyright size={13}  style={{marginRight:3, marginBottom:2}}/></div> 
                    <div className='why'>2022 MAKLER. All right reserved. Terms and condition. privacy policy</div>
                </div>
        </Row>
    </div>
</div>
</div>
  )
}

export default Footer