import React from 'react'
import {Container, Row, Col, Form, Carousel} from "react-bootstrap"
import {useHistory} from 'react-router-dom';
import logo from "../../Assets/images/logo.jpeg"
import img7 from "../../Assets/images/img7.jpeg"
import med1 from "../../Assets/images/exhi1.jpeg"
import med2 from "../../Assets/images/exhi2.jpeg"
import med3 from "../../Assets/images/exhi3.jpeg"
import {BsDot} from 'react-icons/bs'
function Exhibitions() {

    let history = useHistory();

  return (
    <div>
        <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div className="bimage1">
            <Row style={{position: 'relative'}}>
              <Carousel>
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                      <Carousel.Caption className="d-none d-md-block">
                      <h5>We are Proud</h5>
                      <p>To be always on demand.</p>
                      <div style={{border: '1px solid black', padding: '5px', backgroundColor:'red', width:150, marginLeft: '450px'}}>Contact With Us</div>
                    </Carousel.Caption>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
              </Carousel>
            </Row>
          </div>
          <Container>
            <div style={{marginTop: 40}}>
              <Row style={{position: 'relative'}}>
                <Col xs={4} md={4} lg={4}>
                  </Col>
                <Col xs={4} md={4} lg={4}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med1}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med2}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med3}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Col>
                </Row>
            </div>
            </Container>

        <div style={{padding:45}}>
           <Container>
             <div style={{fontSize:35, fontWeight:'550', color:'red', marginTop: 40}}>
             Exhibitions and Events
            </div>
           
            
                  
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Protect your business and your position.
            </p>
                

            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Evolution</div>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Over the past 20 years MG has evolved from a shell based contractor into a multi-diciplinery international trade event, conference and exhibition company.  With our main office and production based in KSA, we are ideally located to serve all GCC and still close enough to international hubs to serve the rest of Middle East.
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Core services</div>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Our core services include Exhibition Design and Build, Bespoke design and Production, Large format Graphics, Fabric Printing, Electrics and Furniture Hire.  We design it make it, plan and install everything and in doing so we take as much pressure and stress from you as possible so you can focus on your needs.
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Secret to our success</div>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            We believe the secret to our success has been to provide a ‘one stop shop’ covering all bases of productions coupled with an expert team who have the ability to deliver every time.  We are forward thinking, energetic and entheusiastic and we strive to do our very best at all times for our clients, our secret is ‘Expertise, Confidence & Success every time’.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            MG Conference creates an environment where members and researchers collaborate on global-changing research and innovation technologies, from human computing, architecture, environment, transport, and sustainability to business, economics, psychology, nursing, healthcare, and more. All the full-text papers, regardless of the presentation type, are peer-reviewed by the respective journal committee members:
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            International Journal of Business, Humanities and Social Sciences provides a forum for sharing timely and up-to-date publication of scientific research and review articles. The journal publishes original research articles at the forefront of business, humanities and social sciences
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            International Journal of Medical, Medicine and Health Sciences provides a forum for sharing timely and up-to-date publication of scientific research and review articles. The journal publishes original research articles at the forefront of medical, medicine and health sciences.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            International Journal of Earth, Energy and Environmental Sciences provides a forum for sharing timely and up-to-date publication of scientific research and review articles. The journal publishes original research articles at the forefront of earth, energy and environmental sciences.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            International Journal of Architectural, Civil and Construction Sciences provides a forum for sharing timely and up-to-date publication of scientific research and review articles. The journal publishes original research articles at the forefront of architectural, civil and construction sciences.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Oman Health Exhibition & Conference is an international annual event that showcases the continued development of the health and medical sector in Oman, the industry’s market potential, and emerging opportunities. It provides a platform for medical professionals, traders, manufacturers, suppliers, service providers and stakeholders of the healthcare and medical industry to explore new opportunities, showcase the latest technologies, services and facilities, and leverage potential for trade and investment.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            The Conference is aligned with the government’s Health Vision 2050 and aimed at addressing the challenges as well as discussing initiatives to improve the sector.
            Launched in 2009 and certified as a UFI-Approved International Event in 2013, it is the most comprehensive international event for healthcare and medical tourism in the sultanate.

            </p>
                
            
          </Container>
        </div>
      
    </div>
  )
}

export default Exhibitions