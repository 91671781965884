import React from 'react'
import './Profile.css';
import {Container, Row, Col, Form} from "react-bootstrap"
import {CgProfile} from 'react-icons/cg'
import air from "../../Assets/images/air1.jpg"
import logo from "../../Assets/images/logo.jpeg"
import {useHistory} from 'react-router-dom';
function Profile() {

    let history = useHistory();
  return (
    <div>
        <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div className="profile">
        <Container>
            <Row style={{marginTop:50}}>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Ghangha</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Rahul</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Dhavid</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Shan</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop:60}}>
            <Col style={{position: 'relative'}} xs={12} md={6}>
                <div>
                   <img src={air} style={{width: 300, height: 185,position: 'absolute', left: 300}}/>
                   <img src={air} style={{width: 300, height: 185, position: 'absolute', top: 140}}/>
                </div>
                </Col>
                <Col style={{position: 'relative'}} xs={12} md={6}>
                <div>
                   <img src={air} style={{width: 300, height: 185,position: 'absolute', left: 300}}/>
                   <img src={air} style={{width: 300, height: 185, position: 'absolute', top: 140}}/>
                </div>
                </Col>
            </Row>
           
        </Container>
        </div>
        <div style={{height:750}}>
            <Container>
            <Row style={{marginTop:50}}>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Ghangha</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Rahul</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Dhavid</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
                <Col xs={12} md={3}>
                    <div className="profs">
                        <div style={{textAlign: 'center', fontSize:20, fontWeight: 'bold', marginTop: 10}}>Shan</div>
                        <div style={{marginTop: 10}}>Blind Text Generator. This dummy text generator can provide you with filler text in ten language</div>
                        <CgProfile size={60} style={{position: 'absolute', top: -30, left: 120}}/>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop:60}}>
            <Col style={{position: 'relative'}} xs={12} md={6}>
                <div>
                   <img src={air} style={{width: 300, height: 185,position: 'absolute', left: 300}}/>
                   <img src={air} style={{width: 300, height: 185, position: 'absolute', top: 140}}/>
                </div>
                </Col>
                <Col style={{position: 'relative'}} xs={12} md={6}>
                <div>
                   <img src={air} style={{width: 300, height: 185,position: 'absolute', left: 300}}/>
                   <img src={air} style={{width: 300, height: 185, position: 'absolute', top: 140}}/>
                </div>
                </Col>
            </Row>
            </Container>
        </div>
    </div>
  )
}

export default Profile