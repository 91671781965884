import React from 'react'
import './Home.css';
import {Container, Row, Col, Form, Carousel} from "react-bootstrap"
import img6 from "../Assets/images/air.jpg"
import img1 from "../Assets/images/im2.jpeg"
import img2 from "../Assets/images/im1.jpeg"
import img3 from "../Assets/images/im4.jpeg"
import img4 from "../Assets/images/im5.jpeg"
import img5 from "../Assets/images/im3.jpeg"
import img7 from "../Assets/images/img7.jpeg"
import {BsDot} from 'react-icons/bs'
import { FaBeer } from 'react-icons/fa';
import { GrAd, GrApple, GrCatalog } from 'react-icons/gr';
import logo from "../../src/Assets/images/logo.jpeg"
import {useHistory} from 'react-router-dom';
function Home() {

  let history = useHistory();

  return (
    <div>
       <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div style={{paddingTop: 65}}>
           <div className="bimage1">
              <Row style={{position: 'relative'}}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                        <Carousel.Caption className="d-none d-md-block">
                        <h5>We are Proud</h5>
                        <p>To be always on demand.</p>
                        <div style={{border: '1px solid black', padding: '5px', backgroundColor:'red', width:150, marginLeft: '450px'}}>Contact With Us</div>
                      </Carousel.Caption>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={img7}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Row>
            </div>
			  </div>
 
        <div className='about'>
            <Row>
                <Col style={{position: 'relative'}} xs={12} md={5}>
                    <img src={img6} className='img2' style={{width:'100%', height:'100%', borderRadius:'20px'}}/>  
                </Col>
                <Col xs={12} md={7} style={{padding:35}}>
                     <div style={{fontSize:42, fontWeight:'550', color:'red'}}>
                     About
                    </div>
                    <div style={{fontSize:25, fontWeight:'550'}}>
                    Makler Group (MG), is an ISO – 9001:2015 Certified Multinational Company with Head office in USA and Branches in KSA & Qatar  
                    </div>
                    <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                    Our story began more than 25 years ago. While working at the American Airlines 1990s, a Pilot spotted a real need in KSA: How to deliver international standards of healthcare and emergency medical assistance to the community and international organizations in the region. He discussed his ideas with his childhood friend, senior businessman, Mohammed Al Fetyan. 
                    </p>
                   <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                   In 1999, they set up UB International. Its purpose was to provide clients with access to local assistance services, clinics, centers of medical excellence, and evacuations when needed. Starting with just 3 staff, UB International grew rapidly from its base in Jeddah into a pan-Asian corporation. In 1998, UB International acquired International Makler Assistance, and the business was renamed Makler Group.
                   </p>
                   <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                   Over the years, Makler Group spread across the globe. As well as expanding geographically, it also expanded its services. Thanks to a series of key acquisitions, and strategic partnerships with leading organizations, we are now able to deliver health, safety and security across the world.
                   </p>
                </Col>    
            </Row>
        </div>

        <div className='serv'>
           <div className='pross'>     
              <img className='vima' src={img1} style={{width:"100%", height:300,}}/>
              <div style={{padding:20}}>
              <Row>
              <div style={{marginTop:10, fontSize:25}}>Medical Tourism</div>
              <Col xs={12} md={10} lg={10}>
              <div>We fully understand that the health and security of your people is imperative to the successful operation and growth of your organization</div>
              </Col>
              <Col xs={12} md={2} lg={2}>
              <div>
                <button onClick={() => history.push('/medical')} style={{borderRadius:10, backgroundColor:'red'}}>Read More</button>
              </div>
              </Col>
              </Row>
              </div>
           </div>

        <div className='pross' style={{marginTop:50}}>
            <img className='vima' src={img2} style={{width:"100%", height:300,}}/>
            <div style={{padding:20}}>
            <Row>
            <div style={{marginTop:10, fontSize:25}}>Document Clearence</div>
            <Col xs={12} md={10} lg={10}>
            <div>Documents Clearance is a division of Top Business and offers document processing and clearing solutions to small and medium size Corporate to reduce their operational cost and improve profitability.</div>
            </Col>
            <Col xs={12} md={2} lg={2}>
            <div>
            <button onClick={() => history.push('/document')} style={{borderRadius:10, backgroundColor:'red'}}>Read More</button>
            </div>
            </Col>
            </Row>
            </div>
        </div>

        <div className='pross' style={{marginTop:50}}>
            <img className='vima' src={img3} style={{width:"100%", height:300,}}/>
            <div style={{padding:20}}>
            <Row>
            <div style={{marginTop:10, fontSize:25}}>Car Rental and Leasing</div>
            <Col xs={12} md={10} lg={10}>
            <div>Our company offers fantastic cars to make your journey comfortable</div>
            </Col>
              <Col xs={12} md={2} lg={2}>
            <div>
             <button onClick={() => history.push('/car')} style={{borderRadius:10, backgroundColor:'red'}}>Read More</button>
            </div>
            </Col>
            </Row>
            </div>
        </div>

        <div className='pross' style={{marginTop:50}}> 
            <img className='vima' src={img4} style={{width:"100%", height:300,}}/>
            <div style={{padding:20}}>
            <Row>
            <div style={{marginTop:10, fontSize:25}}>Aircraft Chartering and Leasing</div>
            <Col xs={12} md={10} lg={10}>
            <div>We service a wide area across all of our Network Locations and offer only exceptional customer service.</div>
            </Col>
              <Col xs={12} md={2} lg={2}>
            <div>
             <button onClick={() => history.push('/aircraft')} style={{borderRadius:10, backgroundColor:'red'}}>Read More</button>
            </div>
            </Col>
            </Row>
            </div>
        </div>

        <div className='pross' style={{marginTop:50}}> 
            <img className='vima' src={img5} style={{width:"100%", height:300,}}/>
            <div style={{padding:20}}>
            <Row>
            <div style={{marginTop:10, fontSize:25}}>Exhibitions and Events</div>
            <Col xs={12} md={10} lg={10}>
            <div>Over the past 20 years MG has evolved from a shell based contractor into a multi-diciplinery international trade event, conference and exhibition company.  </div>
            </Col>
              <Col xs={12} md={2} lg={2}>
            <div>
             <button onClick={() => history.push('/exhibitions')} style={{borderRadius:10, backgroundColor:'red'}}>Read More</button>
            </div>
            </Col>
            </Row>
            </div>
        </div>
        </div>


        <div className='service'>
           <Row>
              <Col xs={12} md={6}>
                <div style={{fontSize:42, fontWeight:'550', color:'red', marginTop:40}}>Why Choose Us</div>
                <p style={{fontSize:18, fontWeight:300, marginTop: 40}}>Our all products have extensive documentation for smooth installation,
                   setup, and use. These products are very Clean & Modern Design.
                    All of our products are created by our professional team members!
                   Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
                   <p style={{fontSize:18, fontWeight:300, marginTop: 20}}> Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
              </Col>
              <Col xs={12} md={3}>
                <div className="pros" style={{backgroundColor:'#fff1f1'}}>
                <FaBeer size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>Free Documention</div>
                <p style={{fontSize:17, marginTop: 20}}>We will provide a free documentation which will be helpful to customize the theme easily.</p>
                </div>
                <div className="pros" style={{backgroundColor:'#f1effc'}}>
                <GrAd size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>100% Response Rate</div>
                <p style={{fontSize:17, marginTop: 20}}>We will ensure 100%
                  response rate within
                  48 hours. Just submit us any
                  query..</p>
                </div>
              </Col>
              <Col xs={12} md={3}>
                <div className="pros" style={{backgroundColor:'#e3fff6'}}>
                <GrApple size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>30 Days Money Back</div>
                <p style={{fontSize:17, marginTop: 20}}>You will get 100% money back guarantee if we will be failure to solve your problem..</p>
                </div>
                <div className="pros" style={{backgroundColor:'#fff6e5'}}>
                <GrCatalog size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>Fast & Speedy!</div>
                <p style={{fontSize:17, marginTop: 20}}>There are plenty of fast themes you can download. Our themes are very fast to load.</p>
                </div>
              </Col>
           </Row>
        </div>


        <div className="conts1">
        <Row>
            <Col xs={12} md={6}>
              <div className="contsm">
              <div className="yemm">Home</div><hr className="hrln" />
              <div className="yemm">E-mail</div><hr className="hrln" />
              <div className="yemm">Mobile</div><hr className="hrln" />
              <div className="yemm">Messages</div><hr className="hrln" />
              <button className="ymbt" >Submit</button>
              </div>
            </Col>

            <Col xs={12} md={6}> 
              <div className="contsm1">
              <div className="cshead">Contact us </div>
                <div className="cspara">
                  Contact us about anything related to our company or services. We'll try to get  back to you soon as possible
                </div>
              <div className="cspara">For more details call:</div>
              <div className="cspara">info@maklergroup.net</div>  
              </div>
            </Col>
        </Row>
        </div>

    </div>
  )
}

export default Home