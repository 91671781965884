import React from 'react'
import {Container, Row, Col, Form, Carousel} from "react-bootstrap"
import {useHistory} from 'react-router-dom';
import logo from "../../Assets/images/logo.jpeg"
import img7 from "../../Assets/images/img7.jpeg"
import med1 from "../../Assets/images/car1.jpeg"
import med2 from "../../Assets/images/car2.jpeg"
import med3 from "../../Assets/images/car3.jpeg"
import {BsDot} from 'react-icons/bs'
function Car() {

    let history = useHistory();

  return (
    <div>
        <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div className="bimage1" >
            <Row style={{position: 'relative'}}>
              <Carousel>
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                      <Carousel.Caption className="d-none d-md-block">
                      <h5>We are Proud</h5>
                      <p>To be always on demand.</p>
                      <div style={{border: '1px solid black', padding: '5px', backgroundColor:'red', width:150, marginLeft: '450px'}}>Contact With Us</div>
                    </Carousel.Caption>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
              </Carousel>
            </Row>
          </div>
          <Container>
            <div style={{marginTop: 40}}>
              <Row style={{position: 'relative'}}>
                <Col xs={4} md={4} lg={4}>
                  </Col>
                <Col xs={4} md={4} lg={4}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med1}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med2}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med3}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Col>
                </Row>
            </div>
            </Container>

        <div style={{padding:45}}>
           <Container>
             <div style={{fontSize:35, fontWeight:'550', color:'red', marginTop: 40}}>
             Car Rental & Leasig
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Our company offers fantastic cars to make your journey comfortable. If you are looking for a rental car or are in the market for a new or used vehicle, let us know. We’re here for customer satisfaction and strive to offer a wide variety of services to better meet our customers’ needs. We offer numerous models suitable for any taste or style and at a price that you can’t beat. We service a wide area across all of our Network Locations and offer only exceptional customer service. Our aim is to satisfy every customer and your no exception.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            In order to suit the varied needs and requirements of our beloved customers whether short term rentals or long term corporate lease (Individuals Corporate, Government sector) MG has set up dedicated departments offering customized services to insure the highest levels of customer satisfaction and retention. These include: 
            </p>
           
          
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Car Rental
            </div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Corporate Leasing</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Used Car Sales</div>
            </div>

            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Car Rental</div>
                
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            MG is one of the largest and fastest growing businesses in the rental field. Today, MG provides its services to customers through more than 5 branches spanning across Five Major Cities. If you need Car, there is one just a short distance away.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            To insure the highest customer satisfaction, MG operates a diverse fleet of modern and reliable vehicles that meet the needs of all customers ranging from small economy cars, medium and large sedans, luxury vehicles and SUVs with competitive and affordable packages based on daily, weekly and monthly rental rates.
            </p>

            <div style={{fontSize:20, fontWeight:'500', marginTop:20}}>Our Rental Packages Include:</div>

            
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Vehicle Insurance</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Collision Damage waiver</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Vehicle Replacement</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Maintenance and Tech Support</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>MG care of its corporate base customers by offering competitive corporate rates and superior customer services lead by a highly trained corporate rental team.</div>
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Whether for company needs or personal use, our highly trained and dedicated team is ready to assist you in any way you need.
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Corporate Leasing</div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            If you are in need of long-term car rentals, then MG is your answer. MG offers long-term corporate leasing to all types of businesses and needs through the ability of choosing the proper car for the required period with monthly pricing at competitive rates. MG Elite corporate partners enjoy no long-term ownership risk, as well as a special maintenance and insurance package that are beneficial to your business.
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Used Car Sales</div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            As a top-notch rental company, we require that our fleet stays as current and reliable as possible. This means that our vehicles are rotated on average, every 18 months, and that the new owner of one of our retired cars is getting a young vehicle with low mileage that has been pristinely maintained for a competitive price.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            You will have a wide range of cars to choose from and will not be hassled by insistent sales people. We are professional in every aspect of our business and want to make sure each and every customer has a pleasant buying experience from beginning to end.
We also sell our used cars to companies and dealers who are looking to purchase in bulk.

            </p>
          </Container>
        </div>
      
    </div>
  )
}

export default Car