import React from 'react'
import './Contact.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import logo from "../../Assets/images/logo.jpeg"
import {useHistory} from 'react-router-dom';
function Contact() {

    let history = useHistory();
  return (
    <div>
       <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div style={{padding:65}}>
        <Row >
            <Col xs={12} md={6}>
            <div className="contsm">
            <div className="yemm">Home</div><hr className="hrln" />
            <div className="yemm">E-mail</div><hr className="hrln" />
            <div className="yemm">Mobile</div><hr className="hrln" />
            <div className="yemm">Messages</div><hr className="hrln" />
            <button className="ymbt" >Submit</button>
           </div>
            </Col>

            <Col xs={12} md={6}> 
            <div className="contsm1">
            <div className="cshead">Contact us </div>
              <div className="cspara">
                Contact us about anything related to our company or services. We'll try to get  back to you soon as possible
              </div>
             <div className="cspara">For more details call:</div>
             <div className="cspara">info@maklergroup.net</div>  
             </div>
            </Col>
            
          </Row>
        

          <Row>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="pros">
                      <div>QATAR</div>
                      <div>Office R-204, 2nd floor, ICONO VIEW</div>
                      <div>Bldg 46,Street 997, Zone 25</div>
                      <div>C Ring Road, Doha Qatar</div>
                      <div>Phone +974 55416738</div>
                </div>
                <div className="pros">
                      <div>SOUDI ARABIA</div>
                      <div>Level 29, Olaya Towes Tower B</div>
                      <div>Intersection Olaya & Mohd A Aziz St</div>
                      <div>Riyadh, Saudi Arabia</div>
                      <div>Phone +966 553596962</div>
                </div>
                <div className="pros">
                      <div>USA</div>
                      <div>17 State Street, Suite 4000</div>
                      <div>40th Floor, New York</div>
                      <div>NY 10004, United States</div>
                      <div>Phone +1 6469803350</div>
                </div>
                </div>
            </Row>
        </div>
    </div>
  )
}

export default Contact