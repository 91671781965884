import React from 'react'
import {Container, Row, Col, Form, Carousel} from "react-bootstrap"
import {useHistory} from 'react-router-dom';
import logo from "../../Assets/images/logo.jpeg"
import img7 from "../../Assets/images/img7.jpeg"
import med1 from "../../Assets/images/docu1.jpeg"
import med2 from "../../Assets/images/docu2.jpeg"
import med3 from "../../Assets/images/docu3.jpeg"
import {BsDot} from 'react-icons/bs'
function Document() {

    let history = useHistory();

  return (
    <div>
       <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div><div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>  
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
        <div className="bimage1">
            <Row style={{position: 'relative'}}>
              <Carousel>
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                      <Carousel.Caption className="d-none d-md-block">
                      <h5>We are Proud</h5>
                      <p>To be always on demand.</p>
                      <div style={{border: '1px solid black', padding: '5px', backgroundColor:'red', width:150, marginLeft: '450px'}}>Contact With Us</div>
                    </Carousel.Caption>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
                <Carousel.Item>
                      <a >
                        <img
                        className="d-block w-100 banner-img"
                        src={img7}
                        alt="fetch"
                        style={{width: '100%', height: 320}}
                        />
                    </a>
                </Carousel.Item>  
              </Carousel>
            </Row>
          </div>
          <Container>
            <div style={{marginTop: 40}}>
              <Row style={{position: 'relative'}}>
                <Col xs={4} md={4} lg={4}>
                  </Col>
                <Col xs={4} md={4} lg={4}>
              <Carousel>
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med1}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med2}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={med3}
                          alt="fetch"
                          style={{width: '100%', height: 320}}
                          />
                      </a>
                  </Carousel.Item>  
                </Carousel>
                </Col>
                </Row>
            </div>
            </Container>

        <div style={{padding:45}}>
           <Container>
             <div style={{fontSize:35, fontWeight:'550', color:'red', marginTop: 40}}>
             Documents Clearance 
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Documents Clearance is a division of Top Business and offers document processing and clearing solutions to small and medium size Corporate to reduce their operational cost and improve profitability.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            A lot of businessmen are attracted to set up their businesses in KSA and Qatar because of its commercial advantages. However, before setting up a business, one must go through a long process of bureaucratic and legal procedures.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Document Clearance merely means obtaining the authorized documents required to start up a business. Documents vary from one company type to another, as it depends on the number of appointed employees, nationalities of employees and many more documents. Documents clearance can be very challenging and confusing as there constant fluctuations in the business laws and the currency. Therefore, having an agency to help you with the process is the most productive way to reduce cost and time.
            </p>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            MG offers its document services to individuals to guarantee them the receipt of approvals and attestations they need in their businesses. We have a specialized team for writing and creating different types of contracts whether they are supplier or client ones. All our contracts are written under the international and Setup Country law to protect you and your business. MG understands the sensitivity and complexities of contract law and offers non-disclosure agreements for all of our DCS clients
            </p>
            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Benefits</div>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Get your legal documents and contracts written for you.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Guarantee all your documents abide by international law.
            </p>
            <p style={{fontSize:18, fontWeight:300, marginTop: 20}}>
            Protect your business and your position.
            </p>
                  
          

            <div style={{fontSize:25, fontWeight:'550', marginTop:20}}>Services We Offer</div>
                
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Trade License
            </div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Trade License Renewal</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Initial Approval</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Trade Name Reservation</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Department of Economics</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Ministry of Labor</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Immigration Department</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Document Attestation</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Ministry of Foreign Affairs</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Pro Services</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Processing of employment visa and renewal</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Arranging visit visa and extensions to such visas</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Processing of documents from Chamber of Commerce</div>
            </div>
            <div className='smdiv' style={{display: 'flex'}}>
              <BsDot size={30} style={{color:'#ff7a00'}} />
              <div style={{marginTop: 2}}>Visa Stamping</div>
            </div>
          </Container>
        </div>
      
    </div>
  )
}

export default Document