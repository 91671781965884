import React from 'react'
import {Helmet} from "react-helmet";
 import Router from '../src/Router'
 import Header from '../src/Components/Header/Header'
 import Footer from '../src/Components/Footer/Footer'
import 'bootstrap/dist/css/bootstrap.min.css';

global.url = 'http://makler.nasscript.com/public/api/';
 global.images = 'http://makler.nasscript.com/public/'

function App() {
  return (
    <div>
       <Helmet>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
      <Router  />
      <Footer />
    </div>
  );
}

export default App;
