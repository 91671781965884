import React from 'react'
import './Service.css';
import {Container, Row, Col, Form} from "react-bootstrap"
import doc1 from "../../Assets/images/doc1.jpeg"
import doc2 from "../../Assets/images/doc2.jpeg"
import doc3 from "../../Assets/images/doc3.jpeg"
import doc4 from "../../Assets/images/doc4.jpeg"
import { FaBeer } from 'react-icons/fa';
import logo from "../../Assets/images/logo.jpeg"
import {useHistory} from 'react-router-dom';
import { GrAd, GrApple, GrCatalog } from 'react-icons/gr';
function Service() {

    let history = useHistory();
  return (
    <div>
        <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
          <div className='service'>
          <Container>
           <Row style={{marginTop:60}}>
              <Col xs={12} md={6}>
                <div style={{fontSize:42, fontWeight:'550', color:'red', marginTop:40}}>Why Choose Us</div>
                <p style={{fontSize:18, fontWeight:300, marginTop: 40}}>Our all products have extensive documentation for smooth installation,
                   setup, and use. These products are very Clean & Modern Design.
                    All of our products are created by our professional team members!
                   Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
                   <p style={{fontSize:18, fontWeight:300, marginTop: 20}}> Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
              </Col>
              <Col xs={12} md={3}>
                <div className="pros" style={{backgroundColor:'#fff1f1'}}>
                <FaBeer size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>Free Documention</div>
                <p style={{fontSize:17, marginTop: 20}}>We will provide a free documentation which will be helpful to customize the theme.</p>
                </div>
                <div className="pros" style={{backgroundColor:'#f1effc'}}>
                <GrAd size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>100% Response Rate</div>
                <p style={{fontSize:17, marginTop: 20}}>We will ensure 100%
                  response rate within
                  48 hours. Just submit us any
                  query..</p>
                </div>
              </Col>
              <Col xs={12} md={3}>
              <div className="pros" style={{backgroundColor:'#e3fff6'}}>
                <GrApple size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>30 Days Money Back</div>
                <p style={{fontSize:17, marginTop: 20}}>You will get 100% money back guarantee if we will be failure to solve your problem..</p>
                </div>
                <div className="pros" style={{backgroundColor:'#fff6e5'}}>
                <GrCatalog size={30}/>
                <div style={{fontSize:20, fontWeight:'bold', marginTop: 20}}>Fast & Speedy!</div>
                <p style={{fontSize:17, marginTop: 20}}>There are plenty of fast themes you can download. Our themes are very fast to load.</p>
                </div>
              </Col>
           </Row>
           </Container>
        </div>
        <div style={{height:600}}>
        <Container>
           <Row style={{marginTop:100}}>
                <Col style={{position: 'relative'}} xs={12} md={6}>
                <div>
                   <img src={doc1} style={{position: 'absolute', left: 180, borderRadius:20}}/>
                   <img src={doc2} style={{position: 'absolute', top: 140, borderRadius:20}}/>
                   <img src={doc3} style={{position: 'absolute', top: 280, left: 180, borderRadius:20}}/>
                   <img src={doc4} style={{position: 'absolute', top: 140, right: 120, borderRadius:20}}/>
                </div>
                </Col>
                <Col xs={12} md={6}>
                <div style={{fontSize:42, fontWeight:'550', marginTop:20}}>These products are</div>
                <p style={{fontSize:18, fontWeight:300, marginTop: 40}}>Our all products have extensive documentation for smooth installation,
                   setup, and use. These products are very Clean & Modern Design.
                    All of our products are created by our professional team members!
                   Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
                   <p style={{fontSize:18, fontWeight:300, marginTop: 20}}> Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
                   <p style={{fontSize:18, fontWeight:300, marginTop: 20}}> Almost all of our products are Unique effects and functionality, 
                   are Fully Responsive, ready to go, and well documented.</p>
                </Col>
           </Row>
        </Container>
        </div>
        
    </div>
  )
}

export default Service