import React from 'react'
import './About.css';
import {Container, Row, Col, Form} from "react-bootstrap"
import img2 from "../../Assets/images/air.jpg"
import imm1 from "../../Assets/images/1.jpeg"
import imm2 from "../../Assets/images/2.jpeg"
import imm3 from "../../Assets/images/3.jpeg"
import imm4 from "../../Assets/images/4.jpeg"
import imm5 from "../../Assets/images/5.jpeg"
import {BsDot} from 'react-icons/bs'
import logo from "../../Assets/images/logo.jpeg"
import {useHistory} from 'react-router-dom';
function About() {

  let history = useHistory();

  return (
    <div>
       <div className='head'>
          <Container>
              <Row>
                <Col xs={12} lg={4}>
                    <div>
                    <img className='metlog' src={logo} style={{marginLeft: 30}} />
                  
                    </div>
                </Col>
                <Col xs={12} lg={5}>
                    <div className='signitem'>
                        <div onClick={() => history.push('/')}> 
                          Home
                        </div>
                        <div onClick={() => history.push('/about')}> 
                          About
                        </div>
                        <div onClick={() => history.push('/service')}> 
                          Services
                        </div> 
                        {/* <div onClick={() => history.push('/profile')}> 
                          Profile
                        </div>   */}
                        <div onClick={() => history.push('/contact')}> 
                          Contact us
                        </div>
                    </div>  
                </Col>
              </Row>
          </Container>
      </div>
     <div className='about' >
            <Row style={{marginTop:65}}>
                <Col style={{position: 'relative'}} xs={12} md={5}>
                    <img src={img2} className='img2' style={{width:'100%', height:'100%', borderRadius:'20px'}}/>  
                </Col>
                <Col xs={12} md={7} style={{padding:35}}>
                     <div style={{fontSize:42, fontWeight:'550', color:'red'}}>
                     About
                    </div>
                    <div style={{fontSize:25, fontWeight:'550'}}>
                    Makler Group (MG), is an ISO – 9001:2015 Certified Multinational Company with Head office in USA and Branches in KSA & Qatar  
                    </div>
                    <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                    Our story began more than 25 years ago. While working at the American Airlines 1990s, a Pilot spotted a real need in KSA: How to deliver international standards of healthcare and emergency medical assistance to the community and international organizations in the region. He discussed his ideas with his childhood friend, senior businessman, Mohammed Al Fetyan. 
                    </p>
                   <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                   In 1999, they set up UB International. Its purpose was to provide clients with access to local assistance services, clinics, centers of medical excellence, and evacuations when needed. Starting with just 3 staff, UB International grew rapidly from its base in Jeddah into a pan-Asian corporation. In 1998, UB International acquired International Makler Assistance, and the business was renamed Makler Group.
                   </p>
                   <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                   Over the years, Makler Group spread across the globe. As well as expanding geographically, it also expanded its services. Thanks to a series of key acquisitions, and strategic partnerships with leading organizations, we are now able to deliver health, safety and security across the world.
                   </p>
                </Col>    
            </Row>
            <Row style={{marginTop:60}}>
                <div style={{fontSize:25, fontWeight:'550', textAlign: 'center'}}>
                ASSISTANCE FOR GLOBAL EVENTS  
                </div>
                <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 20}}>
                We have been privileged to offer assistance before and during the many global events that have occurred in recent years. From major disease outbreaks, such as SARS and the COVID-19 pandemic, to happier international gatherings, such as the Beijing Olympics, we have been closely involved in providing health, safety and security assistance.
                </p>
            </Row>
            <Row style={{marginTop:60}}>
                <div style={{fontSize:20, fontWeight:'550', textAlign: 'center'}}>
                MG is diversified divided into following main Business Sector Divisions: - 
                </div>
                <div style={{display: 'flex',justifyContent:'space-between'}}>
                 
                  <div className='pross' style={{marginTop:50}}>
                    <img className='vima' src={imm3} style={{width:"100%", height:200,}}/>
                    <div style={{padding:10, backgroundColor:'red'}}>
                    <div style={{marginTop:10, fontSize:18, textAlign:'center'}}>Medical and Tourism</div>
                    </div>
                  </div>
                  
                  <div className='pross' style={{marginTop:50}}>
                    <img className='vima' src={imm4} style={{width:"100%", height:200,}}/>
                    <div style={{padding:10, backgroundColor:'red'}}>
                    <div style={{marginTop:10, fontSize:18, textAlign:'center'}}>Documents Clearance</div>
                    </div>
                  </div>
                 
                  <div className='pross' style={{marginTop:50}}>
                    <img className='vima' src={imm2} style={{width:"100%", height:200,}}/>
                    <div style={{padding:10, backgroundColor:'red'}}>
                    <div style={{marginTop:10, fontSize:18, textAlign:'center'}}>Car Rental and Leasing</div>
                    </div>
                  </div>
                 
                  <div className='pross' style={{marginTop:50}}>
                    <img className='vima' src={imm5} style={{width:"100%", height:200,}}/>
                    <div style={{padding:10, backgroundColor:'red'}}>
                    <div style={{marginTop:10, fontSize:18, textAlign:'center'}}>Aircraft Chartering and Leasing</div>
                    </div>
                  </div>
                 
                  <div className='pross' style={{marginTop:50}}>
                    <img className='vima' src={imm1} style={{width:"100%", height:200,}}/>
                    <div style={{padding:10, backgroundColor:'red'}}>
                    <div style={{marginTop:10, fontSize:18, textAlign:'center'}}>Exhibitions and Events</div>
                    </div>
                  </div>
                  </div>
                <p className='parag' style={{fontSize:16, fontWeight:300, marginTop: 50}}>
                Further through its divisions and subsidiaries, MG operates in building materials, cement and bulk materials, finishing materials, contracting, industrial products, automotive products, food products, real estate sectors. MG also sells, markets, imports, exports, and distributes durable materials that includes steel, timber, mesh, wood products, and construction items; building materials and lumber; grey and white cement; and automotive and aviation tires. Additionally, it provides contracting services, such as pipeline construction, waterworks projects, power generation, electricity distribution, and power substations supply and installation; and marine offshore services, tunnelling/water/ civil works. Further, the company manufactures steel products; commercial and high-tensile fasteners; oil, air, fuel, hydraulic transmission, and coolant filters; lubricants; wood-based products; polystyrene blocks that include fruit and vegetable, insulation boards, and ice; PVC waterproofing membranes; and dry mix finishing materials. 
                The company invests in private equity, public listed companies, and institutional and stock markets. 
                </p>
            </Row>
      </div>
      <div>
      
      </div>

{/* <Container>
  <Row>
  <div style={{fontSize:42, fontWeight:'550', textAlign:'center', marginTop:80}}>
       Lorem ipsum dolor sit amet
  </div>
  <p style={{fontSize:18, fontWeight:300, marginTop: 20, textAlign:'center'}}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit,
        sed do eiusmod tempor incididunt ut labore et dolore 
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit
        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  </p>
  </Row>
  <Row>
        <Col xs={12} md={6}>
            <div style={{fontSize:30, fontWeight:'550', textAlign:'center', marginTop: 30}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, textAlign:'center'}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
              sed do eiusmod tempor incididunt ut labore et dolore 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </Col>
        <Col xs={12} md={6}>
            <div style={{fontSize:30, fontWeight:'550', textAlign:'center', marginTop: 30}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, textAlign:'center'}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
              sed do eiusmod tempor incididunt ut labore et dolore 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </Col>    
    </Row>
    <Row style={{marginTop: 40, marginBottom: 40}}>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
    </Row>
    <Row>
        <Col xs={12} md={6}>
            <div style={{fontSize:30, fontWeight:'550', textAlign:'center', marginTop: 30}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, textAlign:'center'}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
              sed do eiusmod tempor incididunt ut labore et dolore 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </Col>
        <Col xs={12} md={6}>
            <div style={{fontSize:30, fontWeight:'550', textAlign:'center', marginTop: 30}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
            </div>
            <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, textAlign:'center'}}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit,
              sed do eiusmod tempor incididunt ut labore et dolore 
              aliqua. Ut enim ad minim veniam, quis nostrud exercitation
              ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
        </Col>    
    </Row>
    <Row style={{marginTop: 40, marginBottom: 40}}>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
      <Col xs={12} md={4}>
      <img src={air} style={{width: 400, height: 170}}/>
      </Col>
    </Row>
</Container> */}

</div>
  )
}

export default About