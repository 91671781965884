import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Home from '../src/Home/Home';
import About from '../src/Components/About/About';
import Service from '../src/Components/Service/Service'
import Contact from './Components/Contact/Contact';
import  Profile from './Components/Profile/Profile';
import Medical from './Components/Profile/Medical';
import Document from './Components/Profile/Document';
import Car from './Components/Profile/Car';
import Aircraft from './Components/Profile/Aircraft';
import Exhibitions from './Components/Profile/Exhibitions';
function rout() {
  return (
    <div>
      <Router>
          <Switch>
              <Home exact path='/' component={Home} />
              <About exact path='/about' component={About} />
              <Service exact path='/service' component={Service} />
              <Contact exact path='/contact' component={Contact} />
              <Profile exact path='/profile' component={Profile} />
              <Medical exact path='/medical' component={Medical} />
              <Document exact path='/document' component={Document} />
              <Car exact path='/car' component={Car} />
              <Aircraft exact path='/aircraft' component={Aircraft} />
              <Exhibitions exact path='/exhibitions' component={Exhibitions} />
          </Switch>
      </Router>
    </div>
  )
}

export default rout;